// The import name must be the same as the file name to work.
// i.e. Sensor67 from '@/pages/Sensor67.svelte' works; NOT SensorTest from '@/pages/Sensor67.svelte

import WebpackerSvelte from 'webpacker-svelte'

import Holiday from '@/pages/scorpio/Holiday.svelte'
import HotNewCarGadget from '@/pages/scorpio/HotNewCarGadget.svelte'
import HotNewCarGadgetCA from '@/pages/scorpio/HotNewCarGadgetCA.svelte'
import Premium50 from '@/pages/scorpio/Premium50.svelte'
import Retargeting from '@/pages/scorpio/Retargeting.svelte'
import Sensor67BFCM from '@/pages/Sensor67BFCM.svelte'
import ThreeGrads from '@/pages/scorpio/ThreeGrads.svelte'
import ThreeTips from '@/pages/scorpio/ThreeTips.svelte'
import Sensor19 from '@/pages/Sensor19.svelte'
import Sensor22 from '@/pages/Sensor22.svelte'
import Sensor25 from '@/pages/Sensor25.svelte'
import Sensor28 from '@/pages/Sensor28.svelte'
import Sensor67 from '@/pages/Sensor67.svelte'
import Sensor67VSL from '@/pages/Sensor67VSL.svelte'
import Sensor67IrresistibleOffer from '@/pages/Sensor67IrresistibleOffer.svelte'
import Sensor67ConsumerAffairs from '@/pages/Sensor67ConsumerAffairs.svelte'
import Sensor67Webflow from '@/pages/Sensor67Webflow.svelte'

WebpackerSvelte.setup({
  Holiday,
  HotNewCarGadget,
  HotNewCarGadgetCA,
  Premium50,
  Retargeting,
  Sensor19,
  Sensor22,
  Sensor25,
  Sensor28,
  Sensor67,
  Sensor67BFCM,
  Sensor67IrresistibleOffer,
  Sensor67ConsumerAffairs,
  Sensor67Webflow,
  Sensor67VSL,
  ThreeGrads,
  ThreeTips,
})
