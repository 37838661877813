<script>
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import Footer from '@/components/landing-page/Footer.svelte'
  import Hero from '@/pages/scorpio/components/io/01Hero.svelte'
  import AsSeenOn from '@/pages/scorpio/components/io/02AsSeenOn.svelte'
  import Quote from '@/pages/scorpio/components/io/03Quote.svelte'
  import WhatIsFixd from '@/pages/scorpio/components/io/04WhatIsFixd.svelte'
  import OtherWays from '@/pages/scorpio/components/io/05OtherWays.svelte'
  import HowItWorks from '@/pages/scorpio/components/io/06HowItWorks.svelte'
  import CompatibilityWidget from '@/pages/scorpio/components/io/07CompatibilityWidget.svelte'
  import BuyBox from '@/pages/scorpio/components/io/08BuyBox.svelte'
  import MoneyBack from '@/pages/scorpio/components/io/09MoneyBack.svelte'
  import List from '@/pages/scorpio/components/io/10List.svelte'
  import FAQ from '@/pages/scorpio/components/io/11FAQ.svelte'
  import About from '@/pages/scorpio/components/io/12About.svelte'

  import Hero2 from '@/pages/scorpio/components/io/01-1Hero.svelte'
  import Quote2 from '@/pages/scorpio/components/io/03-1Quote.svelte'
  import MeetFixd from '@/pages/scorpio/components/io/04-1MeetFixd.svelte'
  import OtherWays21 from '@/pages/scorpio/components/io/05-1OtherWays.svelte'
  import OtherWays22 from '@/pages/scorpio/components/io/05-2OtherWays.svelte'
  import Bonus from '@/pages/scorpio/components/io/06Bonus.svelte'
  import Phones from '@/pages/scorpio/components/io/07Phones.svelte'
  import Everything from '@/pages/scorpio/components/io/08Everything.svelte'

  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Experiment from '@/components/Experiment.svelte'

  export let funnel
  export let gateway
</script>

<main class="md:pb-0">
  <Experiment name="2024-09-27-Irresistible-Offer" let:value>
    {#if value === 'Version 2'}
      <Hero2>
        <div class="flex justify-center md:justify-start w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-2xl">try FIXD FOR $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Hero2>

      <AsSeenOn invert={true} />

      <Quote2 />

      <MeetFixd>
        <div class="flex justify-center md:justify-start w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Save $230+ instantly</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </MeetFixd>

      <OtherWays21>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </OtherWays21>

      <OtherWays22>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </OtherWays22>

      <Bonus />

      <Phones>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Phones>

      <Everything>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Try fixd for $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Everything>
    {:else}
      <Hero>
        <div class="flex justify-center md:justify-start w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-2xl">Claim my Savings</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </Hero>

      <AsSeenOn />

      <Quote />

      <WhatIsFixd>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Save $230+ instantly</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </WhatIsFixd>

      <OtherWays />

      <HowItWorks>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Get $230+ in savings</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </HowItWorks>

      <CompatibilityWidget>
        <h1 class="font-extrabold" slot="header">
          Wondering If FIXD Works With Your Car?
        </h1>

        <div slot="content" class="my-10 md:mb-16">
          <p class="font-medium">
            FIXD works with all gas-powered cars and trucks <br
              class="hidden md:block"
            />built after 1996 and diesels built after 2008.
          </p>
        </div>
        <JumpToBuyBoxButton slot="compatible" maxWidth={56}>
          <span class="uppercase">BUY FIXD NOW</span>
        </JumpToBuyBoxButton>
      </CompatibilityWidget>
    {/if}

    <div class="max-w-[1440px] mx-auto">
      <div class="my-16">
        {#if value === 'Version 2'}
          <h1 class="font-extrabold text-center mt-8 mb-4 md:hidden">
            Try FIXD & Get Over $230 <br class="hidden md:block" />In Instant
            Savings For Just $19.99!
          </h1>
        {:else}
          <h1 class="font-extrabold text-center mt-8">
            Try FIXD & Get Over $230 <br class="hidden md:block" />In Instant
            Savings For Just $19.99!
          </h1>
        {/if}
        <BuyBox {funnel} {gateway} />
      </div>
    </div>

    {#if value === 'Version 2'}
      <HowItWorks>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Get $230+ in savings</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </HowItWorks>

      <CompatibilityWidget>
        <h1 class="font-extrabold" slot="header">
          Wondering If FIXD Works With Your Car?
        </h1>

        <div slot="content" class="my-10 md:mb-16">
          <p class="font-medium">
            FIXD works with all gas-powered cars and trucks <br
              class="hidden md:block"
            />built after 1996 and diesels built after 2008.
          </p>
        </div>
        <JumpToBuyBoxButton slot="compatible" maxWidth={56}>
          <span class="uppercase">BUY FIXD NOW</span>
        </JumpToBuyBoxButton>
      </CompatibilityWidget>

      <LiveReviews backgroundColor="white" />

      <FAQ background="bg-grey-200" />

      <About />
    {:else}
      <MoneyBack />

      <List />

      <LiveReviews backgroundColor="transparent" />

      <FAQ />

      <About />
    {/if}
  </Experiment>
  <Footer />
</main>
