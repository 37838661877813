<script>
  import BFCM from '@/pages/scorpio/bfcm/OldBFCM.svelte'
  import NewBFCM from '@/pages/scorpio/bfcm/NewBFCM.svelte'
  import NewBFCMAlt from '@/pages/scorpio/bfcm/NewBFCMAlt.svelte'

  import Experiment from '@/components/Experiment.svelte'

  export let funnel
  export let gateway
</script>

<main class="md:pb-0">
  <Experiment name="LP-2024-BFCM" let:value>
    {#if value === 'BFCM-New'}
      <NewBFCM {funnel} {gateway} />
    {:else if value === 'BFCM-New-Alt'}
      <NewBFCMAlt {funnel} {gateway} />
    {:else}
      <BFCM {funnel} {gateway} />
    {/if}
  </Experiment>
</main>
